export function mergeSeoTags(
  seoTags: {
    __typename: "Tag";
    tag: string;
    attributes?: { name: string | undefined; property: string | undefined };
    content?: string | null | undefined;
  }[],
  /** Description */
  seoDescription: boolean,
  seoImage: boolean,
  coverUrl: string | undefined,
  description: string | null,
) {
  return seoTags.map((d) => {
    const tagName = d.attributes?.name || d.attributes?.property;
    if (!seoDescription && tagName?.includes("description")) {
      return {
        ...d,
        attributes: { ...d.attributes, content: description || "" },
      };
    } else if (!seoImage && coverUrl !== undefined && tagName?.includes("image")) {
      return {
        ...d,
        attributes: {
          ...d.attributes,
          content: coverUrl,
        },
      };
    }
    return d;
  });
}
