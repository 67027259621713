import React from "react";

export interface Props {
  separator: React.ReactNode;
  children: React.ReactNode;
}

function Intersperse({ separator, children }: Props) {
  return React.createElement(React.Fragment, {}, ...intersperse(separator, React.Children.toArray(children)));
}

export default Intersperse;

function intersperse<T>(separator: T, arr: T[]): T[] {
  if (arr.length === 0) {
    return [];
  } else {
    return arr.slice(1).reduce((xs, x) => xs.concat([separator, x]), [arr[0]]);
  }
}
